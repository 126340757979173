import React from "react"
import * as styles from "./styles.module.scss"
import Container from "../Container/Container"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

//SVG
import Shipping from "../../images/Traders/Icons/Shipping.svg"
import Quality from "../../images/Traders/Icons/Quality.svg"
import Price from "../../images/Traders/Icons/Price.svg"
import Arrow from "../../images/Traders/Icons/Arrow.svg"

const TradersPage = () => {
  const data = useStaticQuery(graphql`
    query {
      header: file(relativePath: { eq: "Traders/trader.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      merces: file(relativePath: { eq: "Traders/merces.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      granite: file(relativePath: { eq: "Traders/granite.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      tiles: file(relativePath: { eq: "Traders/tiles.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      marble: file(relativePath: { eq: "Traders/marble.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      sanitary: file(relativePath: { eq: "Traders/sanitaryware.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <section className={styles.trading}>
      <section className={styles.tradingSection}>
        <Container>
          <h1 className={styles.tradingPrimary}>
            Explore the stones that create Wonders
          </h1>
          <p className={styles.tradingSecondary}>
            UK Traders was formed aligning with the new way of life. We provide
            beautiful design and sustainable Granites, Marbles, Tiles and
            Sanitarywares. Human centric products designed for living.
          </p>
          <div className={styles.tradingImage}>
            <Img
              fluid={data.header.childImageSharp.fluid}
              className={styles.tradingHeader}
              alt="Header"
            />
          </div>
        </Container>
      </section>
      <section className={styles.traders}>
        <Container>
          <div className={styles.tradersBenefit}>
            <h1 className={styles.tradersBenefitPrimary}>
              Benefit <span>inhome</span> for You
            </h1>
            <p className={styles.tradersBenefitSecondary}>
              Plans, quality, specifications and logisitcs - all in one place
            </p>

            <div className={styles.tradersBenefitBox}>
              {/* Item 1 */}
              <div className={styles.tradersBenefitBoxItem}>
                <img
                  src={Shipping}
                  alt="Shipping"
                  className={styles.tradersBenefitBoxItemLogo}
                ></img>
                <div className={styles.tradersBenefitBoxItemText}>
                  <h1 className={styles.tradersBenefitBoxItemTextPrimary}>
                    Fast Shipping
                  </h1>
                  <p className={styles.tradersBenefitBoxItemTextSecondary}>
                    Every single order ships for free. No minimum, no tiers, no
                    fine print.
                  </p>
                </div>
              </div>

              {/* Item 2 */}
              <div className={styles.tradersBenefitBoxItem}>
                <img
                  src={Quality}
                  alt="Quality"
                  className={styles.tradersBenefitBoxItemLogo}
                ></img>
                <div className={styles.tradersBenefitBoxItemText}>
                  <h1 className={styles.tradersBenefitBoxItemTextPrimary}>
                    Quality
                  </h1>
                  <p className={styles.tradersBenefitBoxItemTextSecondary}>
                    We quality check each and every products and ensures that it
                    satisfies our customer needs.
                  </p>
                </div>
              </div>

              {/* Item 3 */}
              <div className={styles.tradersBenefitBoxItem}>
                <img
                  src={Price}
                  alt="Pricing"
                  className={styles.tradersBenefitBoxItemLogo}
                ></img>
                <div className={styles.tradersBenefitBoxItemText}>
                  <h1 className={styles.tradersBenefitBoxItemTextPrimary}>
                    Pricing
                  </h1>
                  <p className={styles.tradersBenefitBoxItemTextSecondary}>
                    All UK products are priced aggressively to provide maximum
                    benefits to our customers.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <section className={styles.tradersCategory}>
            <h1 className={styles.tradersCategoryPrimary}>
              Shop by the category in the <span>inhome</span> catalog
            </h1>
            <p className={styles.tradersCategorySecondary}>
              We’re constantly developing new products and categories from our
              members suggestions.
            </p>

            <div className={styles.tradersCategoryImages}>
              <figure className={styles.tradersCategoryImage__1}>
                <Img
                  fluid={data.granite.childImageSharp.fluid}
                  className={styles.tradersCategoryImage}
                  alt="Image 1"
                />
              </figure>
              <figure className={styles.tradersCategoryImage__2}>
                <Img
                  fluid={data.tiles.childImageSharp.fluid}
                  className={styles.tradersCategoryImage}
                  alt="Image 2"
                />
              </figure>
              <figure className={styles.tradersCategoryImage__3}>
                <Img
                  fluid={data.marble.childImageSharp.fluid}
                  className={styles.tradersCategoryImage}
                  alt="Image 3"
                />
              </figure>
              <figure className={styles.tradersCategoryImage__4}>
                <Img
                  fluid={data.sanitary.childImageSharp.fluid}
                  className={styles.tradersCategoryImage}
                  alt="Image 4"
                />
              </figure>
            </div>
          </section>

          <section className={styles.merces}>
            <Img
              fluid={data.merces.childImageSharp.fluid}
              className={styles.mercesImage}
              alt="Merces"
            />
            <div className={styles.mercesText}>
              <h1 className={styles.mercesTextPrimary}>
                Merces - Introducing the <span>style</span> you’ve always wanted
              </h1>
              <p className={styles.mercesTextSecondary}>
                with Merces, there is always a possiblity in enchancing the look
                of your bathroom with our wide range of Faucets and
                Sanitarywares.
              </p>

              <div className={styles.mercesTextBox}>
                <h1 className={styles.mercesTextBoxPrimary}>
                  Why choose Merces
                </h1>
                <div className={styles.mercesTextBoxSections}>
                  <ul className={styles.mercesTextBoxSection}>
                    <li className={styles.mercesTextBoxSecondary}>
                      Quality Material
                    </li>
                    <li className={styles.mercesTextBoxSecondary}>
                      Aesthetic Look
                    </li>
                  </ul>
                  <ul className={styles.mercesTextBoxSection}>
                    <li className={styles.mercesTextBoxSecondary}>
                      Strong Material
                    </li>
                    <li className={styles.mercesTextBoxSecondary}>
                      10 Years Warranty
                    </li>
                  </ul>
                </div>
              </div>

              <a
                className={styles.mercesLink}
                href="https://www.merces.in/"
                target="_blank"
                rel="noreferrer"
              >
                Check Out
                <img
                  src={Arrow}
                  alt="Arrow"
                  className={styles.mercesLinkIcon}
                ></img>
              </a>
            </div>
          </section>
        </Container>
      </section>
    </section>
  )
}

export default TradersPage

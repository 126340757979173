import React from "react"
import Seo from "../../components/seo"
import Layout from "../../components/layout"
import Traders from "../../components/TradersPage/TradersPage"

const traders = () => {
  return (
    <Layout>
      <Seo title="Traders" />
      <Traders />
    </Layout>
  )
}

export default traders

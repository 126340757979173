// extracted by mini-css-extract-plugin
export var merces = "styles-module--merces--98ecd";
export var mercesImage = "styles-module--mercesImage--5c8bc";
export var mercesLink = "styles-module--mercesLink--45797";
export var mercesLinkIcon = "styles-module--mercesLinkIcon--00446";
export var mercesText = "styles-module--mercesText--2b0e2";
export var mercesTextBox = "styles-module--mercesTextBox--91b74";
export var mercesTextBoxPrimary = "styles-module--mercesTextBoxPrimary--33db7";
export var mercesTextBoxSecondary = "styles-module--mercesTextBoxSecondary--053f9";
export var mercesTextBoxSection = "styles-module--mercesTextBoxSection--d0953";
export var mercesTextBoxSections = "styles-module--mercesTextBoxSections--0ff69";
export var mercesTextPrimary = "styles-module--mercesTextPrimary--d33b3";
export var mercesTextSecondary = "styles-module--mercesTextSecondary--71694";
export var traders = "styles-module--traders--a3c99";
export var tradersBenefit = "styles-module--tradersBenefit--ca8a1";
export var tradersBenefitBox = "styles-module--tradersBenefitBox--39f68";
export var tradersBenefitBoxItem = "styles-module--tradersBenefitBoxItem--c903c";
export var tradersBenefitBoxItemLogo = "styles-module--tradersBenefitBoxItemLogo--fc0b9";
export var tradersBenefitBoxItemText = "styles-module--tradersBenefitBoxItemText--6a123";
export var tradersBenefitBoxItemTextPrimary = "styles-module--tradersBenefitBoxItemTextPrimary--af92f";
export var tradersBenefitBoxItemTextSecondary = "styles-module--tradersBenefitBoxItemTextSecondary--6b2a7";
export var tradersBenefitPrimary = "styles-module--tradersBenefitPrimary--8d9f5";
export var tradersBenefitSecondary = "styles-module--tradersBenefitSecondary--6e154";
export var tradersCategory = "styles-module--tradersCategory--51d2f";
export var tradersCategoryImage = "styles-module--tradersCategoryImage--2db1b";
export var tradersCategoryImage__1 = "styles-module--tradersCategoryImage__1--6a9bf";
export var tradersCategoryImage__2 = "styles-module--tradersCategoryImage__2--3e8b0";
export var tradersCategoryImage__3 = "styles-module--tradersCategoryImage__3--23ea0";
export var tradersCategoryImage__4 = "styles-module--tradersCategoryImage__4--d5584";
export var tradersCategoryImages = "styles-module--tradersCategoryImages--c855d";
export var tradersCategoryPrimary = "styles-module--tradersCategoryPrimary--3f638";
export var tradersCategorySecondary = "styles-module--tradersCategorySecondary--c0edb";
export var trading = "styles-module--trading--82d73";
export var tradingHeader = "styles-module--tradingHeader--07374";
export var tradingImage = "styles-module--tradingImage--4a01c";
export var tradingPrimary = "styles-module--tradingPrimary--58760";
export var tradingSecondary = "styles-module--tradingSecondary--d59bd";
export var tradingSection = "styles-module--tradingSection--f5b42";